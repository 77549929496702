/* https://colorhunt.co/palette/61826479ac78b0d9b1d0e7d2 */
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

:root {
    --body-color: green;
    --main-bg-color: #618264;
    --secondary-bg-color: #79AC78;
    --sam-dev-card-color: var(--main-bg-color);
    --yu-dev-card-color: var(--secondary-bg-color);
    --ben-dev-card-color: var(--main-bg-color);
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

button, a {
    cursor: pointer;
}

html {
    scroll-behavior: smooth;
  }

nav {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-size: 2rem;
    background-color: var(--main-bg-color);
    width: 100%;
}

.logo a, .links a {
    text-decoration: none;
    color: black;
    font-weight: bold;
}

.links {
    display: flex;
    gap: 20px;
}

.logo a:hover {
    background-color: #b1c5b3;
    padding: 1px;
}

.links a:hover {
    background-color: #b1c5b3;
    padding: 1px;
}

#header {
    background-color: var(--main-bg-color);
}

.header {
    background-color: var(--main-bg-color);
    max-width: 1400px;
    margin: 0 auto;
}

.header .container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10rem 2rem 10rem 2rem;
    gap: 3rem;
}

.header p {
    font-size: 4rem;
    font-family: "Permanent Marker", cursive;
    font-weight: 400;
    font-style: normal;
}

.header img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

#about {
    background-color: var(--secondary-bg-color);
}

.about {
    background-color: var(--secondary-bg-color);
    max-width: 1400px;
    margin: 0 auto;
}

.about .container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5rem 2rem 5rem 2rem;
    gap: 3rem;
}

.about p {
    font-size: 4rem;
    font-weight: bold;
    text-align: right;
    line-height: 1;
}

.about img {
    max-width: 100%;
    height: auto;
    border-radius: 50%;
}

#gallery {
    background-color: var(--main-bg-color);
}

#rice-img {
    width: 400px;
    height: 400px;
    opacity: 0.8;
}

.gallery {
    background-color: var(--main-bg-color);
    max-width: 1400px;
    margin: 0 auto;
    text-align: center;
}

.gallery .container {
    padding: 5rem 2rem 5rem 2rem;
}

.gallery h {
    font-size: 5rem;
    font-weight: bold;
}

/* https://youtu.be/McPdzhLRzCg?si=jMkNF5ovVY-mlc6C */
.slider-wrapper {
    position: relative;
    max-width: 48rem;
    margin: 0 auto;
}

.slider {
	display: flex;
	aspect-ratio: 16 / 9;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
	box-shadow: 0 1.5rem 3rem -0.75rem hsla(0, 0%, 0%, 0.25);
	border-radius: 0.5rem;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.slider img {
    flex: 1 0 100%;
    scroll-snap-align: start;
    object-fit: cover;
}

.slider-nav {
    display: flex;
    column-gap: 1rem;
    position: absolute;
    bottom: 1.25rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.slider-nav a {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.75;
    transition: opacity ease 250ms;
    color: rgba(0, 0, 0, 0);
}

.slider-nav a:hover {
    opacity: 1;
}

footer {
    background-color: var(--secondary-bg-color);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

footer .container {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
}

footer a {
    text-decoration: none;
    color: black;
    font-size: 1.25rem;
    font-weight: bold;
}

.developers {
    max-width: 1400px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.developers .container {
    padding: 0rem 2rem 0rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

svg {
    margin-bottom: -10px;
}

.developers img {
    max-width: 100%;
    height: auto;
    border-radius: 50%;
}

.developers h {
    font-size: 3rem;
}

.developers .dev-title {
    font-size: 2rem;
}

.developers .dev-bio {
    font-size: 1.5rem;
    font-family: "Permanent Marker", cursive;
    font-weight: 400;
    font-style: normal;
}

.developers a {
    color: black;
    text-decoration: none;
}

#developers-page > section:nth-child(2) {
    background-color: var(--sam-dev-card-color);
}

#sam-dev-card {
    background-color: var(--sam-dev-card-color);
    padding-top: 2rem;
}

#developers-page > section:nth-child(3) {
    background-color: var(--yu-dev-card-color);
}

#yu-dev-card {
    background-color: var(--yu-dev-card-color);
}

#developers-page > section:nth-child(4) {
    background-color: var(--ben-dev-card-color);
}

#ben-dev-card {
    background-color: var(--ben-dev-card-color);
    padding-bottom: 2rem;
}

.change-log-wrapper {
    min-height: 100vh;
    background: linear-gradient(#618264, #79AC78);
}

.change-log-wrapper ul {
    padding-left: 1rem;
}

.change-log-wrapper li {
    font-size: 2rem;
    text-decoration: none;
}

#support-form {
    background: linear-gradient(#618264, #79AC78);
    min-height: 100vh;
}

.support-form {
    background: linear-gradient(#618264, #79AC78);
    max-width: 1400px;
    margin: 0 auto;
    text-shadow: 2px 2px #B0D9B1;
}

.support-form .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 10rem 5rem 10rem;
    gap: 1rem;
}

.sp-input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.sp-input-wrapper label {
    font-size: 4rem;
    text-align: center;
}

.sp-input-wrapper input, .sp-input-wrapper textarea {
    border: 3px solid black;
}

.sp-input-wrapper input:focus, .sp-input-wrapper textarea:focus {
    outline: none;
}

.sp-input-wrapper input {
    font-size: 2rem;
    padding: 10px;
    width: 50rem;
}

.sp-input-wrapper textarea {
    width: 50rem;
    padding: 10px;
    resize: none;
}

.support-form button {
    height: 3rem;
    width: 8rem;
    border: 5px solid black;
    background-color: black;
    color: white;
    font-size: 1.5rem;
    padding: 5px;
}

.support-form button:hover {
    cursor: pointer;
}

@media screen and (max-width: 992px) {
    .sp-input-wrapper input, .sp-input-wrapper textarea{
        width: 40rem;
    }
}

@media screen and (max-width: 768px) {
    .sp-input-wrapper input, .sp-input-wrapper textarea{
        width: 30rem;
    }
    .about .container {
        flex-direction:  column;
        gap: 0rem;
    }
    .about p {
        text-align: center;
    }
    .header .container {
        flex-direction:  column;
    }
}

@media screen and (max-width: 576px) {
    .sp-input-wrapper input, .sp-input-wrapper textarea{
        width: 20rem;
        font-size: 1rem;
    }
    .about p {
        font-size: 3rem;
    }
    .gallery h {
        font-size: 4rem;
        line-height: 1;
    }
}

@media screen and (max-width: 500px) {
    .sp-input-wrapper input, .sp-input-wrapper textarea{
        width: 15rem;
        font-size: 1rem;
    }
}

@media screen and (max-width: 350px) {
    .sp-input-wrapper label {
        font-size: 3rem;
    }
}

@media screen and (max-width: 300px) {
    .about p {
        font-size: 2rem;
    }
    .gallery h {
        font-size: 3.5rem;
    }
    footer .container {
        flex-wrap: wrap;
        line-height: 0.8;
        gap: 0.25rem;
    }
    footer {
        gap: 0.25rem;
    }
}

#login-page ul {
    list-style: none;
}

#login-page .form-wrapper {
    text-align: center;
    padding: 7rem 0rem;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    background: linear-gradient(#618264, #79AC78);
    text-shadow: 2px 2px #B0D9B1;
}

#login-page fieldset {
    padding: 4rem 0rem;
    max-width: 35rem;
    background-color: var(--secondary-bg-color);
    border: 5px solid black;
    border-radius: 10px;
}

#login-page li {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1rem;
}

#login-page label {
    font-size: 3rem;
}

#login-page input {
    max-width: 20rem;
    padding: 0.5rem;
    border: 1px solid black;
    border-radius: 5px;
}

#login-page legend {
    padding-bottom: 1rem;
    font-size: 5rem;
}

#login-page button {
    padding: .5rem;
    margin: 0.3rem;
    background-color: #D0E7D2;
    border: transparent;
    border-radius: 5px;
}

.role-wrapper {
    display: flex;
    justify-content: center;
}

.lbb {
    font-size: 2rem;
}

.classroom-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 4rem;
    padding: 2rem 0rem;
    flex-wrap: wrap;
}

.classroom {
    /* background: linear-gradient(#9a9a9a, #454545); */
    background-color: #ECFFDC;
    /* max-width: 35rem; */
    max-width: calc(80% - 20px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;
    border-radius: 10px;
    border: 3px solid black;
    gap: 0.3rem;
}

.classroom h {
    font-size: 7rem;
}

.deez {
    padding: 0.3rem 0rem;
    cursor: pointer;
}

.classroom button {
    padding: 0.3rem 0.3rem;
    margin: 0.1rem 0rem;
}

.classroom button:hover {
    scale: 1.1;
}

.classroom .student-count-display {
    font-size: 2.5rem;
}

.classroom .view-students-button {
    font-size: 1.2rem;
    color: white;
    background-color: black;
    border: 3px solid black;
    border-radius: 5px;
}

.classroom .add-students-button {
    border: 3px solid green;
    background-color: green;
    border-radius: 5px
}

.classroom .deez {
    font-size: 1.2rem;
    max-width: 20rem;
    background-color: white;
    padding: 1rem;
}

.classroom .deez:hover {
    color: red;
}

.classroom .classroom-button-wrapper {
    display: flex;
    gap: 5rem;
    justify-content: center;
    align-items: space-around;
}

.classroom .student-grade-item:hover {
    color: lime;
}

.class-list {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 4rem;
    padding: 2rem 0rem;
    flex-wrap: wrap;
}

.class-list button {
    padding: 1rem;
    border: transparent;
    border-radius: 5px;
}

.class-list li {
    max-width: calc(50% - 20px);
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 5rem;
    border-radius: 5px;
    border: 5px solid black;
    /* background: linear-gradient(#9a9a9a, #454545); */
    background-color: #ECFFDC;
}

.class-list h {
    font-size: 5rem;
}

.class-list p {
    font-size: 1.2rem;
}

.class-list button {
    border-radius: 5px;
    background-color: black;
    color: white;
    padding: 0.5rem;
}

.class-list hr {
    border: 1px solid black;
}

@media (max-width: 801px) {
    .class-list li {
        max-width: calc(70% - 20px)
    }
}

@media (max-width: 600px) {
    .class-list li {
        max-width: calc(80% - 20px)
    }
}

@media (max-width: 480px) {
    .class-list li {
        max-width: calc(90% - 20px)
    }
}

@media (max-width: 440px) {
    .class-list h {
        font-size: 3rem;
    }
    .students-assignments-wrapper h {
        font-size: 1rem;
    }
    .class-list p {
        font-size: 1rem;
    }
}

.student-assignments-wrapper h {
    font-size: 2.5rem;
}

.student-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.display-current-user {
    text-align: center;
    background-color: white;
    max-width: 35rem;
    padding: 1rem;
    margin-top: 1rem;
    border: 5px solid black;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.display-current-user span {
    font-size: 2.5rem;
}

.display-current-user #current-user-email {
    font-size: 1.5rem;
}

.display-current-user button {
    background-color: red;
    border: transparent;
    padding: 0.25rem;
    font-size: 1.25rem;
    border-radius: 5px;
}

.full-classroom-wrapper {
    min-height: 100vh;
    background: linear-gradient(#618264, #79AC78);
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.class-bg-img {
    /* background: url('https://t3.ftcdn.net/jpg/04/18/40/68/360_F_418406808_Q9kgFC40dofexEzwDmmRxZmskZUtm3d2.jpg') center/cover no-repeat; */
    background: url('../src/img/rice.jpg') center/cover no-repeat;
    font-family: "Open Sans", sans-serif;
}

.class-bg-tint {
    background-color: rgba(97, 130, 100, 0.85);
}

.assignmentForm {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.assignmentForm input {
    border: transparent;
    border-radius: 5px;
    padding: 0.5rem;
    background-color: var(--main-bg-color);
    color: white;
}

.assignmentForm input::placeholder {
    color: white;
}

@media (max-width: 992px) {
    .classroom {
        max-width: calc(70% - 20px);
    }
}

@media (max-width: 768px) {
    .classroom {
        max-width: calc(85% - 20px);
    }
}

@media (max-width: 576px) {
    .classroom {
        max-width: calc(95% - 20px);
    }
    .classroom h {
        font-size: 3rem;
    }
    .classroom p {
        font-size: 1rem;
    }
    .classroom .classroom-button-wrapper {
        gap: 1rem;
        flex-wrap: wrap;
    }
}
